import '../stylesheets/App.css';
import '../stylesheets/Reviews.css';
import stars from '../assets/stars.svg';
export function Reviews() {
    return(
        <div className="Reviews body">
            <h1>TRUSTED BY ALL!</h1>

            <div className="trustpilot-widget" data-locale="en-GB" data-template-id="5419b6a8b0d04a076446a9ad"
                 data-businessunit-id="5b2e9a9cd34c990001a89301" data-style-height="24px" data-style-width="100%"
                 data-theme="dark">
                <a href="https://uk.trustpilot.com/review/www.humbleservers.com" target="_blank" rel="noopener noreferrer">Trustpilot</a>
            </div>
            <div className="stars">
                <img src={stars} alt="Stars"/>
            </div>


                <div className="ReviewsList">
                    <div className="ReviewsTrack">
                        <div className="AReview">
                            <div>
                                <div className="ReviewProfile">
                                    <img className="ReviewProfileImg" src="https://cdn.discordapp.com/avatars/259172550494388244/468474eefc205b488d9a396f99cf8877?size=1024" alt="ProfileImg"/>
                                    <h3>SnorkyPlay</h3>
                                </div>
                                <p>"Fantastic Service, pretty good compared to all services from other host i have used before.
                                    I just switched from Supreme to Ultimate, and holy hell i can clearly see the performance increases!. overall would recommened anyone to use.
                                    Easy to Understand Panels, Cheap Prices, Quick support too!"</p>
                            </div>
                        </div>

                        <div className="AReview">
                            <div>

                                <div className="ReviewProfile">
                                    <img className="ReviewProfileImg" src="https://cdn.discordapp.com/avatars/355814451045335041/b6939a1b14598f3b17ffc77da2c28145?size=1024" alt="ProfileImg"/>
                                    <h3>al_7alabi</h3>
                                </div>
                                <p>"Excellent service, top tier and amazing prices compared to other services.
                                    Honestly, you would never understand what I'm talking about unless you actually pay for  it and have fun with your friends.."</p>
                            </div>
                        </div>

                        <div className="AReview">
                            <div>
                                <div className="ReviewProfile">
                                    <img className="ReviewProfileImg" src="https://cdn.discordapp.com/avatars/310249646486323200/221ec6dc77408444faafa04f9446755e?size=1024" alt="ProfileImg"/>
                                    <h3>VinnyTheGameDev</h3>
                                </div>
                                <p>"Most server hosts promise this or that but give you less than the fortune you pay for it, with HumbleServers, you get exactly what you pay for at HALF of the price of other hosts.
                                    I had almost no problems with the servers they buy, or if they do the support team is always quick and easy to work with to get the problem fixed"</p>
                            </div>
                        </div>

                        <div className="AReview">
                            <div>

                                <div className="ReviewProfile">
                                    <img className="ReviewProfileImg" src="https://cdn.discordapp.com/avatars/102403833308643328/c61750715cab67594b8a44767cffce90?size=1024" alt="ProfileImg"/>
                                    <h3>GamerKingHD</h3>
                                </div>
                                <p>"I've bought their discord bot hosting, that is dirt cheap, and you're not bandwidth limited, nor cpu limited even for the cheapest tier. They never disappointed in terms of quality, costs and support. I can't recommend this host enough for all of their services.

                                    A true 10 out of 10."</p>
                            </div>
                        </div>

                        <div className="AReview">
                            <div>

                                <div className="ReviewProfile">
                                    <img className="ReviewProfileImg" src="https://cdn.discordapp.com/avatars/753514137950224485/a11bebe8767d010a285379214c5c8a04?size=1024" alt="ProfileImg"/>
                                    <h3>svloog</h3>
                                </div>
                                <p>"Humble is by far the best server hosting I’ve ever used, the support team replies almost instantly, and other than that it’s just amazing, affordable.

                                    I’m gonna be honest, before buying I was a bit suspicious because of the pricing, not anywhere else would I get a server that good for that cheap.

                                    11/10 Definitely would reccomend"</p>
                            </div>
                        </div>

                        <div className="AReview">
                            <div>

                                <div className="ReviewProfile">
                                    <img className="ReviewProfileImg" src="https://cdn.discordapp.com/avatars/455838157796147201/cb74857dce4345a42040287502dc9b2f?size=1024" alt="ProfileImg"/>
                                    <h3>ieatsunchips</h3>
                                </div>
                                <p>"Infinite/10; Staff is very responsive and aren't rude to you if u don't know how to use a plugin, timings, jar files and etc.
                                    I've been using Humble Servers for about 2 months now and I had a good experience overall. I actually didn't know this but every 12 hours or so they backup your server files.
                                    Buy this to gain more players :O"</p>
                            </div>
                        </div>

                        <div className="AReview">
                            <div>

                                <div className="ReviewProfile">
                                    <img className="ReviewProfileImg" src="https://cdn.discordapp.com/avatars/427831806448893962/5932d291ab10100f764e28ed2fc0c172?size=1024" alt="ProfileImg"/>
                                    <h3>kingvilter</h3>
                                </div>
                                <p>"AMAZING HOST! 10/10

                                    What else can you say xD! I highly recommend this host
                                    because the performance is INSANE for the price you pay.
                                    I can run budget with 7+ people on 1.16.5, you just need to tweak a bit then you're good!
                                    This host is DIRT CHEAP and I would've never otherwise had a server if it wasn't for these prices!"</p>
                            </div>
                        </div>




                        <div className="AReview">
                            <div>
                                <div className="ReviewProfile">
                                    <img className="ReviewProfileImg" src="https://cdn.discordapp.com/avatars/259172550494388244/468474eefc205b488d9a396f99cf8877?size=1024" alt="ProfileImg"/>
                                    <h3>SnorkyPlay</h3>
                                </div>
                                <p>"Fantastic Service, pretty good compared to all services from other host i have used before.
                                    I just switched from Supreme to Ultimate, and holy hell i can clearly see the performance increases!. overall would recommened anyone to use.
                                    Easy to Understand Panels, Cheap Prices, Quick support too!"</p>
                            </div>
                        </div>

                        <div className="AReview">
                            <div>

                                <div className="ReviewProfile">
                                    <img className="ReviewProfileImg" src="https://cdn.discordapp.com/avatars/355814451045335041/b6939a1b14598f3b17ffc77da2c28145?size=1024" alt="ProfileImg"/>
                                    <h3>al_7alabi</h3>
                                </div>
                                <p>"Excellent service, top tier and amazing prices compared to other services.
                                    Honestly, you would never understand what I'm talking about unless you actually pay for  it and have fun with your friends.."</p>
                            </div>
                        </div>

                        <div className="AReview">
                            <div>
                                <div className="ReviewProfile">
                                    <img className="ReviewProfileImg" src="https://cdn.discordapp.com/avatars/310249646486323200/221ec6dc77408444faafa04f9446755e?size=1024" alt="ProfileImg"/>
                                    <h3>VinnyTheGameDev</h3>
                                </div>
                                <p>"Most server hosts promise this or that but give you less than the fortune you pay for it, with HumbleServers, you get exactly what you pay for at HALF of the price of other hosts.
                                    I had almost no problems with the servers they buy, or if they do the support team is always quick and easy to work with to get the problem fixed"</p>
                            </div>
                        </div>

                        <div className="AReview">
                            <div>

                                <div className="ReviewProfile">
                                    <img className="ReviewProfileImg" src="https://cdn.discordapp.com/avatars/102403833308643328/c61750715cab67594b8a44767cffce90?size=1024" alt="ProfileImg"/>
                                    <h3>GamerKingHD</h3>
                                </div>
                                <p>"I've bought their discord bot hosting, that is dirt cheap, and you're not bandwidth limited, nor cpu limited even for the cheapest tier. They never disappointed in terms of quality, costs and support. I can't recommend this host enough for all of their services.

                                    A true 10 out of 10."</p>
                            </div>
                        </div>

                        <div className="AReview">
                            <div>

                                <div className="ReviewProfile">
                                    <img className="ReviewProfileImg" src="https://cdn.discordapp.com/avatars/753514137950224485/a11bebe8767d010a285379214c5c8a04?size=1024" alt="ProfileImg"/>
                                    <h3>svloog</h3>
                                </div>
                                <p>"Humble is by far the best server hosting I’ve ever used, the support team replies almost instantly, and other than that it’s just amazing, affordable.

                                    I’m gonna be honest, before buying I was a bit suspicious because of the pricing, not anywhere else would I get a server that good for that cheap.

                                    11/10 Definitely would reccomend"</p>
                            </div>
                        </div>

                        <div className="AReview">
                            <div>

                                <div className="ReviewProfile">
                                    <img className="ReviewProfileImg" src="https://cdn.discordapp.com/avatars/455838157796147201/cb74857dce4345a42040287502dc9b2f?size=1024" alt="ProfileImg"/>
                                    <h3>ieatsunchips</h3>
                                </div>
                                <p>"Infinite/10; Staff is very responsive and aren't rude to you if u don't know how to use a plugin, timings, jar files and etc.
                                    I've been using Humble Servers for about 2 months now and I had a good experience overall. I actually didn't know this but every 12 hours or so they backup your server files.
                                    Buy this to gain more players :O"</p>
                            </div>
                        </div>

                        <div className="AReview">
                            <div>

                                <div className="ReviewProfile">
                                    <img className="ReviewProfileImg" src="https://cdn.discordapp.com/avatars/427831806448893962/5932d291ab10100f764e28ed2fc0c172?size=1024" alt="ProfileImg"/>
                                    <h3>kingvilter</h3>
                                </div>
                                <p>"AMAZING HOST! 10/10

                                    What else can you say xD! I highly recommend this host
                                    because the performance is INSANE for the price you pay.
                                    I can run budget with 7+ people on 1.16.5, you just need to tweak a bit then you're good!
                                    This host is DIRT CHEAP and I would've never otherwise had a server if it wasn't for these prices!"</p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>

    )
}