import '../stylesheets/App.css'
import '../stylesheets/NeedHelp.css'
import discord from "../assets/discord.png";
import agent from "../assets/agent.png"
export function NeedHelp(){
    return(
        <div className="NeedHelp body">
            <h1>Need Help?</h1>
            <div className="helpBoxes">
                <a href="https://discord.humbleservers.com/" target="_blank" className="discord helpButton" rel="noopener noreferrer">
                    <img src={discord} alt="Discord"/>
                    <div className="helpDescription">
                        <h3>Discord Support</h3>
                        <p>Contact us via Discord on our official Discord server to get live chat support!</p>
                    </div>

                </a>
                <a href="https://billing.humbleservers.com/contact.php" target="_blank" className="website helpButton" rel="noopener noreferrer">
                    <img src={agent} alt="Agent"/>
                    <div className="helpDescription">
                        <h3>Website Support</h3>
                        <p>Get support for anything regarding our  services by creating a support query on our website.</p>
                    </div>
                </a>
            </div>

        </div>
    )
}