
let Region = "Europe";
let Plan = "Supreme";
let Data = {
    Budget: {
        vCoreValues: [394, 395, 396, 397],
        basePrice: 0.8,
        rams: [1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 14, 16, 20, 24, 32],
        PIDs: [8, 9, 10, 11, 12, 13, 163, 15, 17, 19, 177, 157, 158, 159, 160],
        vCoresLink: ["&configoption[80]="],
        maxRam: 14,
        displayDefaultMarkers: true,
        displayUltimateMarkers: false,
        displayBudgetWarning: true
    },

    Supreme: {
        vCoreValues: [
            [517, 518, 519, 520, 545],
            [553, 554, 555, 556, 557],
            [597, 598, 599, 600, 601, 602]
        ],
        basePrice: 2.0,
        rams: [1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 14, 16, 20, 24, 32],
        PIDs: [68, 69, 70, 71, 161, 73, 162, 74, 75, 76, 77, 164, 165, 166, 167],
        vCoresLink: ["&configoption[116]=", "&configoption[123]=", "&configoption[128]="],
        maxRam: 14,
        displayDefaultMarkers: true,
        displayUltimateMarkers: false,
        displayBudgetWarning: false
    },

    Ultimate: {
        vCoreValues: [259, 248, 260, 249, 261], // Renamed from "numbers"
        basePrice: 2.75,
        rams: [8, 12, 16, 20, 24, 28, 32],
        PIDs: [114, 116, 118, 127, 119, 128, 120],
        vCoresLink: ["&configoption[53]="],
        maxRam: 6,
        displayDefaultMarkers: false,
        displayUltimateMarkers: true,
        displayBudgetWarning: false
    }
};

const BillLink = "https://billing.humbleservers.com/cart.php?a=add&pid=";


export function update() {

    const RAM_DISPLAY = document.getElementById("ramDisplay");
    const REGION_DISPLAY = document.getElementById("regionDisplay");
    const PLAN_DISPLAY = document.getElementById("planDisplay");
    const PRICE_DISPLAY = document.getElementById("priceDisplay");
    const VCORES_DISPLAY = document.getElementById("vCoresDisplay");

    const RegionLink = Region === "North America" ? document.getElementById("NAButton") : document.getElementById("EUButton");
    const selectedPlan = Data[Plan];

    const Ram = document.getElementById("Ram").value;
    const vCores = parseInt(document.getElementById("vCores").value);
    const BuyButton = document.getElementById("BuyButton");

    document.getElementById("Ram").max = selectedPlan.maxRam;
    document.getElementById("DefaultMarkers").style.display = selectedPlan.displayDefaultMarkers ? "flex" : "none";
    document.getElementById("UltimateMarkers").style.display = selectedPlan.displayUltimateMarkers ? "flex" : "none";
    document.getElementById("BudgetWarning").style.display = selectedPlan.displayBudgetWarning ? "flex" : "none";

    let BuyLink = BillLink + selectedPlan.PIDs[Ram];
    let cost = ((selectedPlan.rams[Ram] * selectedPlan.basePrice) + (vCores * 5)).toFixed(2);

    if (vCores > 0) {
        let vCoresLink = "";

        if (Plan === "Budget" || Plan === "Ultimate") {
            vCoresLink = selectedPlan.vCoresLink[0] + selectedPlan.vCoreValues[vCores - 1];
        } else if (Plan === "Supreme") {
            if (Ram <= 2) {
                vCoresLink = selectedPlan.vCoresLink[0] + selectedPlan.vCoreValues[0][vCores - 1];
            } else if (Ram <= 7) {
                vCoresLink = selectedPlan.vCoresLink[1] + selectedPlan.vCoreValues[1][vCores - 1];
            } else {
                vCoresLink = selectedPlan.vCoresLink[2] + selectedPlan.vCoreValues[2][vCores - 1];
            }
        }

        BuyLink += vCoresLink;
    }

    const vCoresOffset = (Plan === "Budget" ? 2 : (Plan === "Ultimate" ? 4 : (Ram <= 2 ? 2 : (Ram <= 7 ? 3 : 4))));
    const totalVCores = vCores + vCoresOffset;

    VCORES_DISPLAY.innerText = totalVCores + " vCores";
    RAM_DISPLAY.innerText = selectedPlan.rams[Ram] + "GB";
    REGION_DISPLAY.innerText = Region;
    PLAN_DISPLAY.innerText = Plan;
    PRICE_DISPLAY.innerText = "$" + cost + "/month";

    BuyLink += RegionLink.getAttribute("data-" + Plan);
    BuyButton.href = BuyLink;
}

export function regionChange(value) {
    Region = value;
    update();
}

export function planChange(value) {
    Plan = value;
    update();
}



export function getPlan(){
    update();
    return Plan;
}


export function getLink(plan,ram, region){
    const NA = {
        Budget: "&configoption[79]=331",
        Supreme: "&configoption[155]=734",
        Ultimate: "&configoption[52]=728"
    }
    const EU = {
        Budget: "&configoption[79]=330",
        Supreme: "&configoption[155]=733",
        Ultimate: "&configoption[52]=245"
    }
    const loc = region === "Europe" ? EU[plan] : NA[plan];
    const index = Data[plan].rams.indexOf(ram);
    console.log("PID: ",index);
    let Link = BillLink + Data[plan].PIDs[index] + loc;
    return Link;
}