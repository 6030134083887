
const locations = [
    {
        "id": "EU",
        "name": "Europe",
        "url": "https://eus1.humbleservers.com:8080/"
    },
    {
        "id": "MIA",
        "name": "Miami",
        "url": "https://mia-s1.humbleservers.com:8080/"
    },
    {
        "id": "NYC",
        "name": "New York",
        "url": "https://nyc-s1.humbleservers.com:8080/"
    },
    {
        "id": "BHN",
        "name": "Canada",
        "url": "https://nab1.humbleservers.com:8080/"
    }
]

class Location {
    constructor(id, name, pingUrl) {
        this.id = id;
        this.name = name;
        this.pingUrl = pingUrl;
    }

    // Get ping for one location
    async getPing() {
        return new Promise(async (resolve, reject) => {
            const start = Date.now();

            try {
                fetch(this.pingUrl, {
                    mode: "no-cors"
                })
                    .then(() => {
                        resolve(Math.round(Date.now() - start));
                    })
            } catch(err) {}
        });
    }

    async getAveragePing() {
        return new Promise(async (resolve, reject) => {
            // Create an array for the ping results to go in
            const entries = [];

            // Empty array of 10 items
            const arr = new Array(10).fill(0);
            for await(let index of arr) {
                await new Promise(async resolve => {
                    // Add the ping entry
                    entries.push(await this.getPing());

                    // Wait 500ms for the next ping
                    setTimeout(resolve, 500);
                });
            }

            resolve(entries.length > 0 ? Math.round(entries.reduce((prev, curr) => prev + curr) / entries.length) : 0);
        });
    }

    // Get all Location instances
    static getAll() {
        return locations
            // Create a new Location instance
            .map(location => new Location(location.id, location.name, location.url));
    }

    // Get response times for all locationns
    static getPings() {
        return Promise.all(this.getAll()
            // Get the ping and add it to the location object
            .map(async location => Object.assign({}, location, {
                ping: await location.getAveragePing()
            })));
    }
}

// Get the start button

let allowPing = true;

const clearPingResults = (button) => {
    const results = document.querySelectorAll(".ping-result")
    button.classList.remove("Pinger");
    for(let i = 0; i < results.length; i++) {
        results[i].remove();
    }
}





// Event for when the ping button is clicked
export async function pingIt(){
    // If the button has already been clicked, return
    if(!allowPing) return;
    const pingButton = document.getElementById("pingButton");
    const pingButtonText = pingButton.innerText;
    clearPingResults(pingButton);

    allowPing = false;
    pingButton.innerText = "Pinging";
    pingButton.classList.add("Pinger");

    const originalText = pingButton.innerText;

    // Function to add a dot to the start button
    const addDot = () => !allowPing ? pingButton.innerText += "." : null;

    // Reset the text for the start button
    const resetText = () => pingButton.innerText = originalText;

    // Add 3 dots to start button
    const addDots = () => {
        if(allowPing) return;

        setTimeout(addDot, 1000);
        setTimeout(addDot, 2000);
        setTimeout(addDot, 3000);
        setTimeout(() => {
            if(allowPing) return;

            resetText();

            addDots();
        }, 4000);
    }

    addDots();
    // Get locations with pings
    const locationsWithPing = await Location.getPings();
    locationsWithPing.forEach(location => {
        const element = document.getElementById(`Location-${location.id}`);
        const pingElement = document.createElement("p");
        element.style.display = "";
        pingElement.classList.add("ping-result");
        pingElement.innerHTML = `<h3>${location.name}: </h3> ${location.ping}ms`;

        if(location.ping <= 150) {
            pingElement.classList.add("ping-green");
        } else if(location.ping <= 300) {
            pingElement.classList.add("ping-yellow");
        } else {
            pingElement.classList.add("ping-red");
        }
        element.appendChild(pingElement);
    });

    allowPing = true;
    resetText();
    pingButton.innerText = pingButtonText;
    pingButton.classList.remove("ping-active");
}
