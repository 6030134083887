import '../stylesheets/Navbar.css';
import '../stylesheets/App.css'
import logo from '../assets/logo.png';
import {useState} from "react"; // Import the logo image
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router-dom";

export function Navbar() {

    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();
    const currentPage = useLocation();

    function isPage(path){
        return currentPage.pathname === path;
    }

    function navigateTo(path) {
        navigate(path);
        setMenuOpen(false);
    }

    return (

        <nav className={`Fader navbar  ${menuOpen ? 'open' : ''}`}>
            <div className="LogoHamCombo">
                <div className="navbar-logo" onClick={() => navigateTo("/")}>
                    <img src={logo} alt="Logo"/>
                </div>
                <button className={`menu-toggle ${menuOpen ? 'open' : ''}`} onClick={() => setMenuOpen(!menuOpen)}></button>
            </div>


                <div className={`navbar-links ${menuOpen ? 'open' : ''}`}>
                    <p onClick={() => navigateTo("/minecraft")} className={`${isPage("/minecraft") ? 'NavClicked' : ''}`}>Minecraft</p>


                    <p onClick={() => navigateTo("/vps")} className={`${isPage("/vps") ? 'NavClicked' : ''}`}>VPS</p>


                    <p onClick={() => navigateTo("/games") } className={`${isPage("/games") ? 'NavClicked' : ''}`}>Games</p>


                    <p onClick={() => navigateTo("/discord-bot")} className={`${isPage("/discord-bot") ? 'NavClicked' : ''}`}>Discord Bot</p>


                    <div className="login-dropdown">
                        <p id="login"></p>
                        <div className="login-options">
                            <a href="https://billing.humbleservers.com/clientarea.php" target="_blank">Client Area</a>
                            <a href="https://panel.humbleservers.com/" target="_blank">Game Panel</a>
                            <a href="https://vps.humbleservers.com:4083" target="_blank">VPS Panel</a>
                        </div>
                    </div>
                </div>
        </nav>
    );
}
