import './style/CreateServer.css';
import React from "react";
import {Players} from "../../scripts/StoreChoices";

export function ChoosePlayers(props) {
    return (
        <div className="Main Transit">
            <h1>Choose Number of Players</h1>
            <div className="PlayersButton">

                <button onClick={() => {
                    props.onComponentChange('Final');
                    Players(0);
                }}>0 - 10
                </button>

                <button onClick={() => {
                    props.onComponentChange('Final');
                    Players(1);
                }}>10-30
                </button>

                <button onClick={() => {
                    props.onComponentChange('Final');
                    Players(2);
                }}>30-50
                </button>
                <button onClick={() => {
                    props.onComponentChange('Final');
                    Players(3);
                }}>50+
                </button>
            </div>
            <button onClick={() => props.onComponentChange('Previous')} className="Back">Back</button>
        </div>
    )
}