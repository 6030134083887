import './style/CreateServer.css';
import React from "react";
import {Version} from "../../scripts/StoreChoices";

export function ChooseVersion(props) {
    return (
        <div className="Main Transit">
            <h1>Choose Version</h1>
            <div className="VersionButton">

                <button onClick={() => {
                    props.onComponentChange('ChoosePlayers');
                    Version("new");
                }}>1.20-1.16
                </button>

                <button onClick={() => {
                    props.onComponentChange('ChoosePlayers');
                    Version("mid");
                }}>1.15-1.13
                </button>

                <button onClick={() => {
                    props.onComponentChange('ChoosePlayers');
                    Version("old");
                }}>1.12-1.8
                </button>

            </div>
            <button onClick={() => props.onComponentChange('Previous')} className="Back">Back</button>
        </div>
    )
}