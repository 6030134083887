import '../stylesheets/App.css';
import '../stylesheets/CommonCards.css';
import {Layout} from "./Layout";
import version from "../assets/version.png";
import mods from "../assets/mods.png";
import settings from "../assets/settings.png";
import discord from "../assets/discord-card-feature.png";
import backups from "../assets/backup.png";
import database from "../assets/database.png";

export function Games(){
    return(
        <Layout>
            <div className="Games body Fader">
            <div className="Plans">
                <div className="Valheim Descriptions">
                    <div className="PackageHeading">
                        <h2>Valhiem</h2>
                        <p>$6.99</p>
                    </div>
                    <div className="Features">
                        <li><box-icon name='check-shield' type='solid' color='#D5D5D5' ></box-icon> <p>DDoS protected</p></li>
                        <li><box-icon name='keyboard' type='solid' color='#D5D5D5' ></box-icon> <p>Full SFTP Access</p></li>
                        <li><box-icon name='wifi' color='#D5D5D5' ></box-icon> <p>Unmetered BBandwidth</p></li>
                        <li><box-icon name='cloud-upload' color='#D5D5D5' ></box-icon> <p>1Gbps Uplink</p></li>
                        <li><box-icon name='user-plus' color='#D5D5D5' ></box-icon> <p>Unlimited Player Slots</p></li>
                        <li><box-icon name='hdd' type='solid' color='#D5D5D5' ></box-icon> <p>Unmetered Nvme Storage</p></li>
                    </div>
                    <a className="ViewButton" id="BudgetButton" href="https://billing.humbleservers.com/cart.php?a=add&pid=109">Buy</a>
                </div>
                <div className="Ark Descriptions">
                    <div className="PackageHeading">
                        <h2>ARK: Survival</h2>
                        <p>$9.99</p>
                    </div>
                    <div className="Features">
                        <li><box-icon name='check-shield' type='solid' color='#D5D5D5' ></box-icon> <p>DDoS protected</p></li>
                        <li><box-icon name='keyboard' type='solid' color='#D5D5D5' ></box-icon> <p>Full SFTP Access</p></li>
                        <li><box-icon name='wifi' color='#D5D5D5' ></box-icon> <p>Unmetered BBandwidth</p></li>
                        <li><box-icon name='cloud-upload' color='#D5D5D5' ></box-icon> <p>1Gbps Uplink</p></li>
                        <li><box-icon name='user-plus' color='#D5D5D5' ></box-icon> <p>Unlimited Player Slots</p></li>
                        <li><box-icon name='hdd' type='solid' color='#D5D5D5' ></box-icon> <p>Unmetered Nvme Storage</p></li>
                    </div>
                    <a className="ViewButton" id="BudgetButton" href="https://billing.humbleservers.com/cart.php?a=add&pid=126">Buy</a>
                </div>
                <div className="GarrysMod Descriptions">
                    <div className="PackageHeading">
                        <h2>Garry's Mod</h2>
                        <p>$5.0</p>
                    </div>
                    <div className="Features">
                        <li><box-icon name='check-shield' type='solid' color='#D5D5D5' ></box-icon> <p>DDoS protected</p></li>
                        <li><box-icon name='keyboard' type='solid' color='#D5D5D5' ></box-icon> <p>Full SFTP Access</p></li>
                        <li><box-icon name='wifi' color='#D5D5D5' ></box-icon> <p>Unmetered BBandwidth</p></li>
                        <li><box-icon name='cloud-upload' color='#D5D5D5' ></box-icon> <p>1Gbps Uplink</p></li>
                        <li><box-icon name='user-plus' color='#D5D5D5' ></box-icon> <p>Unlimited Player Slots</p></li>
                        <li><box-icon name='hdd' type='solid' color='#D5D5D5' ></box-icon> <p>Unmetered Nvme Storage</p></li>
                    </div>
                    <a className="ViewButton" id="BudgetButton" href="https://billing.humbleservers.com/cart.php?a=add&pid=86">Buy</a>
                </div>
                <div className="CSGO Descriptions">
                    <div className="PackageHeading">
                        <h2>CS:GO</h2>
                        <p>$5.0</p>
                    </div>
                    <div className="Features">
                        <li><box-icon name='check-shield' type='solid' color='#D5D5D5' ></box-icon> <p>DDoS protected</p></li>
                        <li><box-icon name='keyboard' type='solid' color='#D5D5D5' ></box-icon> <p>Full SFTP Access</p></li>
                        <li><box-icon name='wifi' color='#D5D5D5' ></box-icon> <p>Unmetered BBandwidth</p></li>
                        <li><box-icon name='cloud-upload' color='#D5D5D5' ></box-icon> <p>1Gbps Uplink</p></li>
                        <li><box-icon name='user-plus' color='#D5D5D5' ></box-icon> <p>Unlimited Player Slots</p></li>
                        <li><box-icon name='hdd' type='solid' color='#D5D5D5' ></box-icon> <p>Unmetered Nvme Storage</p></li>
                    </div>
                    <a className="ViewButton" id="BudgetButton" href="https://billing.humbleservers.com/cart.php?a=add&pid=80">Buy</a>
                </div>
            </div>

                <br/>
                <br/>
                <br/>
                <h1 className="FeatureHeading">Features with each server!</h1>

                <div className="FeatureCards">
                    <div className="Reason">
                        <div className="ReasonHeading">
                            <img src={database} alt="data"/>
                            <h3>High Speed Data</h3>
                        </div>
                        <p>Say goodbye to slow connections and hello to lightning-fast data speeds at the touch of a button!</p>
                    </div>
                    <div className="Reason">
                        <div className="ReasonHeading">
                            <img src={mods} alt="mods"/>
                            <h3>Third Party Mods</h3>
                        </div>
                        <p>Dive into an exciting universe of third-party mods, where your gaming experience reaches new heights with an array of enhancements and possibilities, all at your fingertips.</p>
                    </div>
                    <div className="Reason">
                        <div className="ReasonHeading">
                            <img src={settings} alt="settings"/>
                            <h3>SFTP Access</h3>
                        </div>
                        <p>Effortlessly manage your server files with easy and full SFTP access.</p>
                    </div>
                    <div className="Reason">
                        <div className="ReasonHeading">
                            <img src={discord} alt="discord"/>
                            <h3>Discord Support</h3>
                        </div>
                        <p>24/7 Discord support just a message away - get help anytime, anywhere.</p>
                    </div>
                    <div className="Reason">
                        <div className="ReasonHeading">
                            <img src={backups} alt="backups"/>
                            <h3>Daily backups</h3>
                        </div>
                        <p>Daily backups for peace of mind - protect your data and never lose progress.</p>
                    </div>
                    <div className="Reason">
                        <div className="ReasonHeading">
                            <img src={database} alt="database"/>
                            <h3>Free MySQL</h3>
                        </div>
                        <p>Free MySQL databases - power your server with reliable, fast database storage.</p>
                    </div>
                </div>
        </div>
        </Layout>
    )
}









