import '../stylesheets/App.css'
import '../stylesheets/WhyUs.css'
import agent from "../assets/agent.png"
import amd from '../assets/amd.png'
import money from '../assets/money.png'
export function WhyUs(){
    return(
        <div className="WhyUs body">
            <h1>Why Choose Us?</h1>
            <div className="WhyBoxes">
                <div className="amd FeatureBox">
                    <img src={amd} alt="AMD"/>
                    <div className="featuresDescription">
                        <h3>Ryzen Processors</h3>
                        <p>Equipped with the most powerful Ryzen lineup of processors for maximum performance.</p>
                    </div>
                </div>
                <div className="support FeatureBox">
                    <img src={agent} alt="Agent"/>
                    <div className="featuresDescription">
                        <h3>Rapid Support </h3>
                        <p>We are always ready to help you via Discord or Website support system.</p>
                    </div>
                </div>
                <div className="affordable FeatureBox">
                    <img src={money} alt="Money"/>
                    <div className="featuresDescription">
                        <h3>Affordable</h3>
                        <p>We offer plans as low as $1/GB making us one of the most affordable hosting service providers.</p>
                    </div>
                </div>

            </div>

        </div>
    )
}