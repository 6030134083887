import {getLink} from "./Minecraft";

let flavor, region,  players;
let version ="new";

let auto = {
    new: {
        Vanilla: [8, 10, 14, 20],
        Plugins: [6, 8, 12, 16],
        Modded: [8, 12, 16, 20],
        Proxy: [1, 1, 1, 1],
        Bedrock: [2, 2, 4, 4]
    },
    mid: {
        Vanilla: [6, 8, 12, 16],
        Plugins: [6, 8, 10, 14],
        Modded: [8, 12, 16, 20],
        Proxy: [1, 1, 1, 1],
        Bedrock: [2, 2, 4, 4]
    },
    old: {
        Vanilla: [4, 6, 8, 12],
        Plugins: [4, 6, 6, 8],
        Modded: [8, 12, 16, 20],
        Proxy: [1, 1, 1, 1],
        Bedrock: [2, 2, 4, 4]
    }
}

let autoUltimate = {
    new: {
        Vanilla: [8, 12, 16, 20],
        Plugins: [8, 8, 12, 16],
        Modded: [8, 12, 16, 20]
    },
    mid: {
        Vanilla: [8, 8, 12, 16],
        Plugins: [8, 8, 12, 16],
        Modded: [8, 12, 16, 20]
    },
    old: {
        Vanilla: [8, 8, 8, 12],
        Plugins: [8, 8, 8, 8],
        Modded: [8, 12, 16, 20]
    }
}


export function getRecommendation() {
    let newRAM, price, plan;
        if((players === 3 || Flavor === "Modded") && flavor != "Bedrock"){
        plan = "Ultimate";
        newRAM = autoUltimate[version][flavor][players];
        price = newRAM * 2.75;
    }
    else{
        plan = "Supreme";
        newRAM = auto[version][flavor][players];
        price = newRAM * 2.0;
    }
    const link = getLink(plan,newRAM, region);
    console.log("Recommdation: ", players, version, flavor, region, plan, link);
    let arr = [plan,newRAM,price,link];
    return(arr);
}

export function Flavor(value){
    flavor = value;
}

export function Location(value){
    region = value;
}

export function Version(value){
    version = value;
}

export function Players(value){
    players = value;
}

export function ClearChoices(){

}
