import React from 'react';
import ReactDOM from 'react-dom/client';
import {Minecraft} from "./modules/Pages/Minecraft";
import {Games} from "./modules/Pages/Games"
import {VPS} from './modules/Pages/VPS'
import {DiscordBot} from "./modules/Pages/DiscordBot"
import {Home} from "./modules/Pages/Home";
import {
    createBrowserRouter, Navigate,
    RouterProvider,
} from "react-router-dom";
import {TOS} from "./modules/Pages/TOS";
import {Privacy} from "./modules/Pages/Privacy";
import {AboutUs} from "./modules/Pages/AboutUs";

const router = createBrowserRouter([
    {
        path: "*",
        element: <Navigate replace to="/" />,
    },
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/minecraft",
        element: <Minecraft/>,
    },
    {
        path: "/vps",
        element: <VPS/>,
    },
    {
        path: "/games",
        element: <Games/>,
    },
    {
        path: "/discord-bot",
        element: <DiscordBot/>,
    },
    {
        path: "/tos",
        element: <TOS/>,
    },
    {
        path: "/privacy",
        element: <Privacy/>,
    },
    {
        path: "/about",
        element: <AboutUs/>,
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
const width = window.innerWidth;
console.log(width);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
