import './style/CreateServer.css';
import React from "react";
import {Flavor} from "../../scripts/StoreChoices";

export function ChoosePlatform(props) {
    return (
        <div className="Main Transit">
            <h1>Choose Platform</h1>
            <div className="PlatformButton">
                <button onClick={() => {
                    props.onComponentChange('ChoosePlayers');
                    Flavor("Bedrock");
                }}>Bedrock
                </button>

                <button onClick={() => props.onComponentChange('ChooseFlavor')}>Java</button>
            </div>
            <button onClick={() => props.onComponentChange('Previous')} className="Back">Back</button>
        </div>
    )
}