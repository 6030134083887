import '../stylesheets/Island.css'
import '../stylesheets/App.css'
import island from '../assets/island.png'
import diamond from '../assets/diamond.png'

export function Island(props){
    return(
        <div className="body Transit">
            <div className="island">
                <img className="islandimg" src={island} alt="Island"/>
                <div className="features">
                    <h1>Minecraft Server Hosting</h1>
                    <ul>
                        <li>24/7 Online</li>
                        <li>Free Anti DDos</li>
                        <li>Unlimited Slots</li>
                        <li>SFTP Access</li>
                        <li>Version Changer</li>
                        <li>Mods & Modpack Installer</li>
                    </ul>

                    <p className="createServer" onClick={() => props.onComponentChange('ChooseLocation')}>
                        <img src={diamond} alt="Diamond"/>
                        <span>Create a server</span>
                    </p>
                </div>
            </div>

        </div>
    )
}