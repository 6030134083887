import React from "react";
import {Navbar} from "../Home/Navbar";
import {Footer} from "../Home/Footer";

export function Layout({children}){
    return(
        <div>
            <Navbar/>
            <div>{children}</div>
            <Footer/>
        </div>
    )
}
