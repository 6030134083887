import {Island} from "../Home/Island";
import {NeedHelp} from "../Home/NeedHelp";
import {Locations} from "../Home/Locations";
import {WhyUs} from "../Home/WhyUs";
import {EasyPanel} from "../Home/EasyPanel";
import {Wiki} from "../Home/Wiki";
import {Reviews} from "../Home/Reviews";
import React, {useEffect, useState} from "react";
import {Layout} from "./Layout";
import ChooseLocation from "../Home/Create Server/ChooseLocation";
import {ChoosePlatform} from "../Home/Create Server/ChoosePlatform";
import {ChoosePlayers} from "../Home/Create Server/ChoosePlayers";
import {ChooseFlavor} from "../Home/Create Server/ChooseFlavor";
import {ChooseVersion} from "../Home/Create Server/ChooseVersion";
import {Final} from "../Home/Create Server/Final";

export function Home() {
    const [currentPage, setPage] = useState("Island");
    const [pages, setPages] = useState(["Island"]);

    let render;

    const changePage = (page) => {
        if (page === "Previous") {
            const newPages = [...pages];
            newPages.pop();
            setPages(newPages);
            setPage(newPages[newPages.length - 1]);
        } else {
            setPages([...pages, page]);
            setPage(page);
        }
    }

    switch (currentPage) {
        case "Island":
            render = <Island onComponentChange={changePage}/>
            break;
        case "ChooseLocation":
            render = <ChooseLocation onComponentChange={changePage}/>
            break;
        case "ChoosePlatform":
            render = <ChoosePlatform onComponentChange={changePage}/>
            break;
        case "ChooseFlavor":
            render = <ChooseFlavor onComponentChange={changePage}/>
            break;
        case "ChooseVersion":
            render = <ChooseVersion onComponentChange={changePage}/>
            break;
        case "ChoosePlayers":
            render = <ChoosePlayers onComponentChange={changePage}/>
            break;
        case "Final":
            render = <Final onComponentChange={changePage}/>
            break;
        default:
            render = <Island onComponentChange={changePage}/>
    }



    return (
        <Layout>
            <div className="Fader">
                {render}
                <NeedHelp/>
                <Locations/>
                <WhyUs/>
                <EasyPanel/>
                {/*<Wiki/>*/}
                <Reviews/>
            </div>

        </Layout>
    )
}