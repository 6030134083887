import './style/CreateServer.css';
import React from "react";
import {Flavor, Players} from "../../scripts/StoreChoices";

export function ChooseFlavor(props) {
    return (
        <div className="Main Transit">
            <h1>Choose Flavor</h1>
            <div className="PlayersButton">
                <button onClick={() => {
                    props.onComponentChange('ChooseVersion');
                    Flavor("Vanilla");
                }}>Vanilla
                </button>

                <button onClick={() => {
                    props.onComponentChange('ChooseVersion');
                    Flavor("Plugins");
                }}>Plugins
                </button>

                <button onClick={() => {
                    props.onComponentChange('ChooseVersion');
                    Flavor("Modded");
                }}>Mods
                </button>

                <button onClick={() => {
                    props.onComponentChange('Final');
                    Flavor("Proxy");
                    Players(0);
                }}>Bungeecord
                </button>
            </div>
            <button onClick={() => props.onComponentChange('Previous')} className="Back">Back</button>
        </div>
    )
}