import '../stylesheets/App.css';
import '../stylesheets/Footer.css';
import logo from '../assets/logo.png'
import {useNavigate} from "react-router-dom";
export function Footer(){

    const navigate = useNavigate();

    function navigateTo(path) {
        navigate(path);
        window.scrollTo({ top: 0, behavior: "smooth" });
    }


    return (
        <div className="Footer">

            <div className="FooterItems">
                <div className="FooterLogo">
                    <img src={logo} alt="" />
                </div>
                <div className="FooterColumn">
                    <h1 className="FooterHeading">Support</h1>
                    <p>
                        <a href="https://discord.humbleservers.com/" target="_blank" rel="noreferrer">Live Chat - Discord</a>
                    </p>
                    <p>
                        <a href="https://billing.humbleservers.com/contact.php" target="_blank" rel="noreferrer">Billing Support</a>
                    </p>
                    <p><a href="https://forms.gle/tX4PjKZxtmDdQC1d7" target="_blank" rel="noreferrer">Partnerships</a></p>
                </div>
                <div className="FooterColumn">
                    <h1 className="FooterHeading">Services</h1>
                    <p onClick={() => navigateTo("/minecraft")}> Minecraft Hosting</p>
                    <p onClick={() => navigateTo("/vps")}> VPS Hosting </p>
                    <p onClick={() => navigateTo("/games")}> Games Hosting</p>
                    <p onClick={() => navigateTo("/discord-bot")}>Discord Bot Hosting</p>
                </div>
                <div className="FooterColumn">
                    <h1 className="FooterHeading">Solutions</h1>
                    <p onClick={() => navigateTo("/about")}>About Us</p>
                    <p onClick={() => navigateTo("/tos")}>Terms of Service</p>
                    <p onClick={() => navigateTo("/privacy")}>Privacy Policy</p>
                    <p>
                        <a href="https://uptime.humbleservers.com/"  target="_blank" rel="noreferrer">
                            Server Uptime Status
                        </a>
                    </p>
                </div>
            </div>
            <p className="text-center copyright">
                Copyright 2018-2023 © HumbleServers. All Rights Reserved.
            </p>
        </div>
    );
}