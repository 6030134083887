import '../stylesheets/App.css';
import '../stylesheets/EasyPanel.css';
import panel1 from '../assets/panel1.png';
import panel2 from '../assets/panel2.png';
import panel3 from '../assets/panel3.png';
import panel4 from '../assets/panel4.png';

export function EasyPanel(){
    return(
        <div className="EasyPanel body">
            <h1>Swift Panel!</h1>

            <div className="SlideShow">
                    <div className="slides">
                        <div className="slider">
                            <div className="image"> <img src={panel1} alt="Panel4"/> </div>
                        </div>
                        <div className="slider">
                            <div className="image"> <img src={panel2} alt="Panel4"/> </div>
                        </div>
                        <div className="slider">
                            <div className="image"> <img src={panel3} alt="Panel4"/> </div>
                        </div>
                        <div className="slider">
                            <div className="image"> <img src={panel4} alt="Panel4"/> </div>
                        </div>
                    </div>
                <div className="markers">
                    <div className="marker">
                    </div>
                    <div className="marker"></div>
                    <div className="marker"></div>
                    <div className="marker">
                        <div className="on"></div></div>
                </div>

            </div>
        </div>
    )
}