import './style/CreateServer.css';
import React from "react";
import {Location} from "../../scripts/StoreChoices";

function ChooseLocation(props) {
    return (
        <div className="Main Transit">
            <h1>Choose a Location</h1>
            <div className="LocationButton">

                <button onClick={() => {
                    props.onComponentChange('ChoosePlatform');
                    Location("North America");
                }}>North America
                </button>

                <button onClick={() => {
                    props.onComponentChange('ChoosePlatform');
                    Location("Europe");
                }}>Europe
                </button>

            </div>
            <button onClick={() => props.onComponentChange('Previous')} className="Back">Back</button>
        </div>
    )
}

export default ChooseLocation;