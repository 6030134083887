import '../stylesheets/App.css';
import {Layout} from "./Layout";
import '../stylesheets/TOS.css';

export function AboutUs (){
    return (
        <Layout>
            <div className="TOS Fader">
                <center>
                    <h1>About HumbleServers</h1>
                    <p className="Intro" style={{ textAlign: "left" }}>
                        Welcome to HumbleServers - Powerful hosting now made affordable!
                    </p>
                </center>

                <p className="Intro" style={{ textAlign: "left" }}>
                    At HumbleServers, we are passionate about providing top-quality Minecraft hosting as well as other digital service hosting solutions at prices that won't break the bank. Our journey began with a simple yet powerful idea: to create a hosting platform that's reliable, affordable, and designed for both beginners and experienced users alike. We believe that everyone should have access to powerful servers without the hassle and high costs typically associated with hosting services.
                </p>

                <h2>Our Story</h2>
                <p>
                    HumbleServers emerged from a shared love for Minecraft and the desire to offer fellow gamers the best possible experience. What started as a modest project quickly evolved into a full-fledged hosting provider. With years of combined experience in both gaming and web hosting, our team has the knowledge and expertise to deliver the quality service you deserve.
                </p>

                <h2>Our Mission</h2>
                <p>
                    <strong>Empowering Gamers and Creators:</strong> We believe in empowering gamers and creators by providing the tools and infrastructure they need to thrive. Whether you're building epic Minecraft worlds, launching a website, or starting an online community, we're here to support your dreams.
                    <br /><br />
                    <strong>Quality without Compromise:</strong> We are dedicated to delivering high-performance servers and web hosting services. Our commitment to quality is unwavering, and we continually invest in cutting-edge hardware and technology to ensure you get the best performance.
                    <br /><br />
                    <strong>Affordability for All:</strong> "Humble" isn't just in our name; it's at the core of everything we do. We've designed our pricing to be affordable for individuals, small businesses, and large enterprises alike. No hidden fees, no surprises—just great hosting at a fair price.
                    <br /><br />
                    <strong>Customer-Centric Approach:</strong> Your satisfaction is our priority. Our customer support team is available around the clock to assist you with any questions or issues you may have. We're always here to lend a helping hand.
                </p>

                <h2>What We Offer</h2>
                <p>
                    - <strong>Minecraft Hosting:</strong> Unleash your creativity with our powerful and reliable Minecraft hosting services. Whether you're building, surviving, or adventuring, our servers will provide the performance you need to take your gameplay to the next level.
                    <br /><br />
                    - <strong>Game Server Hosting:</strong> Extend your gaming experience beyond Minecraft. Host your favorite game servers with us and enjoy smooth, lag-free gameplay with your friends or fellow gamers.
                    <br /><br />
                    - <strong>VPS Hosting:</strong> Take control of your online presence with our Virtual Private Server (VPS) hosting. Whether you're a developer, a business owner, or simply want to run custom applications, our VPS solutions offer the flexibility and performance you require.
                    <br /><br />
                    - <strong>Discord Bot Hosting:</strong> Elevate your Discord server with custom bots. Our Discord bot hosting allows you to create, deploy, and manage bots that enhance your server's functionality and engagement.
                </p>

                <p className="Intro" style={{ textAlign: "left" }}>
                    We're here to support your diverse hosting needs, whether you're a gamer, a developer, a community leader, or a business owner. Join HumbleServers, and let us provide you with the hosting solutions you deserve. Your digital world awaits!
                </p>

                <h2>Join the HumbleServers Family</h2>
                <p className="Intro" style={{ textAlign: "left" }}>
                    We invite you to join our ever-growing community of gamers, builders, and creators. HumbleServers is more than just a hosting provider; it's a place where your digital dreams can become reality. Together, we'll embark on a journey of fun, exploration, and success.

                    Thank you for considering HumbleServers as your hosting partner. We're excited to be a part of your online adventure, and we can't wait to see what you'll create with our hosting services. If you have any questions or need assistance, please don't hesitate to reach out to our friendly support team.

                    <strong>Your success is our success, and we're here to help you every step of the way.</strong>
                </p>
            </div>

        </Layout>


    )
}
