import './style/CreateServer.css';
import '../../stylesheets/CommonCards.css';
import React from 'react'
import {getRecommendation} from "../../scripts/StoreChoices";
import {useNavigate} from "react-router-dom";

export function Final(props) {

    const Plan = getRecommendation();
    const navigate = useNavigate();


    return (
        <div className="Main Transit">
            <div className="Descriptions">
                <div className="PackageHeading">
                    <h2>{Plan[0]}</h2>
                    <p className="RecommendationPrice">{Plan[1]}GB RAM - ${Plan[2]}/month</p>
                </div>

                <div className="Features">
                    <li>
                        <box-icon type='solid' name='bolt' color='#D5D5D5'></box-icon>
                        <p>Ryzen 3800 - Europe</p></li>
                    <li>
                        <box-icon type='solid' name='bolt' color='#D5D5D5'></box-icon>
                        <p>AMD 7351P - North America</p></li>
                    <li>
                        <box-icon name='user-plus' color='#D5D5D5'></box-icon>
                        <p>Unlimited Player Slots</p></li>
                    <li>
                        <box-icon name='dropbox' type='logo' color='#D5D5D5'></box-icon>
                        <p>20GB Base Storage</p></li>
                    <li>
                        <box-icon name='data' type='solid' color='#D5D5D5'></box-icon>
                        <p>Free MySQL Databases - 2</p></li>
                    <li>
                        <box-icon name='cloud-lightning' type='solid' color='#D5D5D5'></box-icon>
                        <p>Backup Options Available</p></li>
                </div>
                <a href={Plan[3]} className="ViewButton" id="FinalButton">Buy</a>
            </div>
            <h1>OR</h1>
            <button onClick={() => navigate("/minecraft")} className="ExploreAll">Explore All Options</button>
            <button onClick={() => window.location.reload()} className="Back">Reset</button>
        </div>
    )
}