import '../stylesheets/App.css';
import {Layout} from "./Layout";
import '../stylesheets/TOS.css';

export function TOS (){
    return (
        <Layout>
        <div className="TOS Fader">
            <center>
                <h1> Terms Of Services</h1>
                <p className="Intro" style={{textAlign: "left"}}>By using Humble Servers products and services, you agree and will comply to all
                    of our policies. We have the right to cancel, suspend, or terminate any service or product provided with
                    or without a refund or notice or warning for any reason. We also reserve the right to change any Modify
                    our Terms of Service at any time without client notification. It is up to the client to periodically
                    check with the recent Terms of Service. If any client does not comply with the new Terms of Service
                    modifications, his or her service will be terminated.
                    <br /> <br />
                        "Client", "Users", "You" and "Your" refers to you, the person accessing this website and accepting
                        the Company terms and conditions. "The Company", "Ourselves", "We" and "Us", refers to our Company
                        Humble Servers.
                </p>
            </center>
            <br/>
                <h2>Payment, Refunds, and Cancellations</h2>
                <p>It is the buyer's responsibility to pay before the payment is due, failure to do that will result in the
                    service suspension, and then termination of the service after 7 days of overdue. If the buyer wants his
                    service unsuspended because the buyer failed to pay in time, there will be late fees added to their invoice.
                    We have the right to suspend or terminate at any time after your service is overdue, if your server gets
                    terminated we are not obligated to retrive your data.</p>

                <p>We only provide refunds within 24 hours. Payment using our Payment Processor Stripe might incur fees when
                    refunding in full. We have the right to deny refunds for any reason even if it's within the 24 hour period.
                    We strictly do not give out refunds for our dedicated servers and reseller plans. We could only refund the
                    buyer in website credit, if HumbleServers agrees to such, we are not obligated to give the buyer any refunds
                    at any time. Any Promotional offer's are not eligible for a refund. Also, if the buyer forgets to disable
                    his subscription, Humble Servers will not refund him under any means; it is not our responsibility that the
                    buyer did not turn off their subscription even after the cancellation.</p>

                <p>The Client is responsible for cancelling any recurring payment subscriptions for Our Services that they have
                    set up with the PayPal or other payment providers upon service termination.</p>

                <h2> Disputes</h2>
                <p>Any payment chargeback or charges will result in the termination of your service with us. Any legal threats
                    will also result in the immediate termination of your services(s) with us.
                </p>

                <h2>Support</h2>
                <p> All of our support is unmanaged. We only provide you with the essentials needed to get full access to your
                    service. We provide basic knowledge of hosting and we may be able to assist you in questions you may have.
                    We will however provide support for any issues regarding hardware, control panels. Also, we will not provide
                    support of any kind to servers containing cracked plugins or illegal files.
                </p>
                <h2>SLA</h2>
                <p>
                    At HumbleServers we gurantee the network uptime of 99.9%, In the unlikely event that the service is
                    unaccessuble by the client, The client may open a ticket to be compensated by our support team depending on
                    the service downtime duration. We will look into the situation and investigate the circumstances of the
                    downtime. We are not held responsible for any unplanned outages due to third-party software or hardware
                    failures.</p>
                <h2>Backups</h2>
                <p>
                    We provide off-site backups for all services for a monthly subscription. Each server is backed up every 12
                    hours and are kept on our off-site server which is not accessible directly by the client. If you require a
                    backup to be restored or to be in a seperate folder in your server files you may request it in the billing
                    panel or by tickets on our discord server. There is a 3$ fee for services without the backup subscription.
                    If your server has been terminated within the past 14 days we might have a backup available for a 5$ fee.
                    HumbleServers is not liable for any loss of backups/files.

                </p>

                <h2>Affiliate Marketing</h2>
                <p>
                    Each user is provided with their own affiliate links in their billing account. Customers are entitled for a
                    15% comissions of any signups made via their affiliate link.
                    Please note that faliure to use the affiliate link is not our responsibility and any in case of any user not
                    able to signup with your affiliate, shall not be added later on regardless any proofs from the user.

                </p>

                <h2>Acceptance Use Policy</h2>
                <p>
                    CPU cores are shared in shared hosting, we give and allow a certain amount of virtual core to each server
                    which is listed when ordering if you are using 100% of the cores for a extended period of time we will ask
                    the client to reduce their usage. Failure to reduce their usage after being asked will result in the
                    immediate termination of account and services without a refund. We also offer more virtual cores as a paid
                    addon which can use their cores at 100% with a prolonged amount of time. Clients using shared website
                    hosting are not allowed to run or publish any games on the website node, failure to follow this will result
                    in the immediate termination of your service.

                </p>
                <h2>Forbidden Activity</h2>
                <p>We do no tolerate Denial of Service attacks, Port scanning, Botnets, TOR exit nodes, Proxies, Open DNS
                    resolver, illegal downloads, Virtual currency mining, Crawlers, IP spoofing, Phishing, Ram Hacks, Stresser
                    Website and Adult materials.
                </p>
                <h2>Important Note:</h2>
                <p>
                    All files on users services are users responsibility. Humble Servers holds no responsibility for what our
                    client's upload. We do not allow any forms of copyright violation and will result in immediate suspension.
                    <br />
                        Please report forbidden activities and DMCA Copyright infringement to <span
                    tyle={{fontWeight: "bold"}}>admin@humbleservers.com</span>
                </p>

                <br/>
                    <br />
                        <center>
                            <h3 className="pt-5" style={{fontWeight: "bold", color: "#186ec5"}}>The Terms of Services can be modified
                                at any time without notice.</h3>
                            <h3 >Last Edited On: 2022-12-17</h3>
                        </center>
        </div>
            </Layout>


    )
}
