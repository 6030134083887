import '../stylesheets/App.css';
import '../stylesheets/CommonCards.css';
import {useEffect, useState} from "react";
import {planChange, regionChange, update} from "../scripts/Minecraft";
import {Layout} from "./Layout";
import {changeFlavour, changePlayer, changeVersion} from "../scripts/NeedHelp";
import backups from "../assets/backup.png";
import database from "../assets/database.png";
import discord from "../assets/discord-card-feature.png";
import mods from "../assets/mods.png";
import settings from "../assets/settings.png";
import version from "../assets/version.png";

export function Minecraft(){
    const [plan, setPlan] = useState("Supreme");
    const [region, setRegion] = useState("North America");


    useEffect(() => {
        regionChange(region);
        planChange(plan)
    }, [plan, region]);

    return(
        <Layout>
            <div className="Minecraft body Fader">
            <div className="Plans">
                <div className={`BudgetDescription Descriptions ${plan === "Budget" ? 'SelectedCard' : ''}`}>
                    <div className="PackageHeading">
                        <h2>Budget</h2>
                        <p>$0.80/GB</p>
                    </div>
                    <div className="Features">
                        <li><box-icon type='solid' name='bolt' color='#D5D5D5'></box-icon>              <p>Ryzen 3800 - Europe</p></li>
                        <li><box-icon type='solid' name='bolt' color='#D5D5D5'></box-icon>              <p>AMD 7351P - North America</p></li>
                        <li><box-icon name='user-plus' color='#D5D5D5' ></box-icon>                     <p>Unlimited Player Slots</p></li>
                        <li><box-icon name='hdd' type='solid' color='#D5D5D5' ></box-icon>           <p>20GB Base Storage</p>  </li>
                        <li><box-icon name='data' type='solid' color='#D5D5D5' ></box-icon>             <p>Free MySQL Databases - 2</p></li>
                        <li><box-icon name='cloud-lightning' type='solid' color='#D5D5D5' ></box-icon>  <p>Backup Options Available</p> </li>
                    </div>
                    <a href="#Regions" className={`ViewButton ${plan === "Budget" ? 'SelectedButton' : ''}`} id="BudgetButton" onClick={(e) => {
                        e.preventDefault();
                        const targetElement = document.getElementById("Regions");
                        targetElement.scrollIntoView({ behavior: "smooth" });
                        setPlan("Budget");
                        planChange("Budget");
                    }}>VIEW</a>
                </div>
                <div className={`SupremeDescription Descriptions ${plan === "Supreme" ? 'SelectedCard' : ''}`}>
                    <div className="PackageHeading">
                        <h2>Supreme</h2>
                        <p>$2.0/GB</p>
                    </div>
                    <div className="Features">
                        <li><box-icon type='solid' name='bolt' color='#D5D5D5'></box-icon>              <p>Ryzen 5950X - Europe</p></li>
                        <li><box-icon type='solid' name='bolt' color='#D5D5D5'></box-icon>              <p>Ryzen 5800/5600X - North America</p></li>
                        <li><box-icon name='user-plus' color='#D5D5D5' ></box-icon>                     <p>Unlimited Player Slots</p></li>
                        <li><box-icon name='hdd' type='solid' color='#D5D5D5' ></box-icon>           <p>Unmetered Nvme Storage</p>  </li>
                        <li><box-icon name='data' type='solid' color='#D5D5D5' ></box-icon>             <p>Free MySQL Databases - 5</p></li>
                        <li><box-icon name='cloud-lightning' type='solid' color='#D5D5D5' ></box-icon>  <p>Free Backups - 3 Days</p> </li>
                    </div>
                    <a href="#Regions"  className={`ViewButton ${plan === "Supreme" ? 'SelectedButton' : ''}`} id="SupremeButton" onClick={(e) => {
                        e.preventDefault();
                        const targetElement = document.getElementById("Regions");
                        targetElement.scrollIntoView({ behavior: "smooth" });
                        setPlan("Supreme");
                        planChange("Supreme");
                    }}>VIEW</a>
                </div>
                <div className={`UltimateDescription Descriptions ${plan === "Ultimate" ? 'SelectedCard' : ''}`}>
                    <div className="PackageHeading">
                        <h2>Ultimate</h2>
                        <p>$2.75/GB</p>
                        <div className="DediCores"><p>Dedicated vCores</p></div>
                    </div>
                    <div className="Features">
                        <li><box-icon type='solid' name='bolt' color='#D5D5D5'></box-icon>              <p>Ryzen 5950X - Europe</p></li>
                        <li><box-icon type='solid' name='bolt' color='#D5D5D5'></box-icon>              <p>Ryzen 5800 - North America</p></li>
                        <li><box-icon name='user-plus' color='#D5D5D5' ></box-icon>                     <p>Unlimited Player Slots</p></li>
                        <li><box-icon name='hdd' type='solid' color='#D5D5D5' ></box-icon>           <p>Unmetered Nvme Storage</p>  </li>
                        <li><box-icon name='data' type='solid' color='#D5D5D5' ></box-icon>             <p>Free MySQL Databases - 5</p></li>
                        <li><box-icon name='cloud-lightning' type='solid' color='#D5D5D5' ></box-icon>  <p>Free Backups - 7 Days</p> </li>
                    </div>
                    <a href="#Regions" className={`ViewButton ${plan === "Ultimate" ? 'SelectedButton' : ''}`} id="UltimateButton" onClick={(e) => {
                        e.preventDefault();
                        const targetElement = document.getElementById("Regions");
                        targetElement.scrollIntoView({ behavior: "smooth" });
                        setPlan("Ultimate");
                        planChange("Ultimate");
                    }}>VIEW</a>
                </div>
            </div>

            <div className="Region" id="Regions">
                    <p id = "NAButton" className={`RegionButton ${region === "North America" ? 'RegionClicked' : ''}`} onClick={() => {
                        setRegion("North America");
                        regionChange("North America")
                    }} data-Budget="&configoption[79]=331" data-Supreme="&configoption[155]=734" data-Ultimate="&configoption[52]=728"><h6>North America</h6></p>

                    <p id="EUButton" className={`RegionButton ${region === "Europe" ? 'RegionClicked' : ''}`} onClick={() => {
                        setRegion("Europe");
                        regionChange("Europe")
                    }} data-Budget="&configoption[79]=330" data-Supreme="&configoption[155]=733" data-Ultimate="&configoption[52]=245"><h6>Europe</h6></p>
            </div>

            <div className="OptionsSelector">
                <div className="AllOptions">
                    <div className="OptionsHeading">
                        <p id="ramDisplay">6GB</p>
                        <span>|</span>
                        <p id="vCoresDisplay">3vCores</p>
                        <span>|</span>
                        <p id="planDisplay">Supreme</p>
                    </div>
                    <div className="Selectors">
                        <p id="BudgetWarning">"Budget" plans are best for light usage and versions up to 1.12. <br/>For heavy mods or versions above 1.12, "Supreme" or "Ultimate" plans are recommended.</p>
                        <p>RAM</p>
                        <input className="ConfigSelector" type="range" defaultValue="7" min="0" max="14" id= "Ram" onChange={() => update()}/>
                        <ul className="ConfigMarkers" id="DefaultMarkers">
                            <li value="1" data-vCores="0">1</li>
                            <li value="2" data-vCores="0">2</li>
                            <li value="3" data-vCores="0">3</li>
                            <li value="4" data-vCores="1">4</li>
                            <li value="5" data-vCores="1">5</li>
                            <li value="6" data-vCores="1">6</li>
                            <li value="7" data-vCores="1">7</li>
                            <li value="8" data-vCores="1">8</li>
                            <li value="10" data-vCores="2">10</li>
                            <li value="12" data-vCores="2">12</li>
                            <li value="14" data-vCores="2">14</li>
                            <li value="16" data-vCores="2">16</li>
                            <li value="20" data-vCores="2">20</li>
                            <li value="24" data-vCores="2">24</li>
                            <li value="32" data-vCores="2">32</li>
                        </ul>
                        <ul className="ConfigMarkers" id="UltimateMarkers">
                            <li value="1">8</li>
                            <li value="2">12</li>
                            <li value="3">16</li>
                            <li value="4">20</li>
                            <li value="5">24</li>
                            <li value="6">28</li>
                            <li value="7">32</li>
                        </ul>

                        <p>Extra vCores</p>
                        <input className="ConfigSelector" type="range" min="0" defaultValue="0" max="4" id="vCores" list="VCoreList" onChange={() => update()}/>
                        <ul className="ConfigMarkers">
                            <li value="1">0</li>
                            <li value="2">1</li>
                            <li value="3">2</li>
                            <li value="4">3</li>
                            <li value="5">4</li>
                        </ul>
                    </div>

                    <div className="OptionsEnding">
                        <p id='priceDisplay'>$12/month</p>
                        <span>|</span>
                        <p id="regionDisplay">Europe</p>
                        <a id="BuyButton" href="https://billing.humbleservers.com/index.php?rp=/store/supreme-minecraft-hosting">Buy Now</a>
                    </div>
                </div>

                <div className="AutoSelector">
                    <div className="AutoSelectorHeading">
                        <p>Need Help?</p>
                    </div>
                    <div className="AiSelect">
                        <p>Simply enter your configuration, and we will select the best plan for you!!</p>

                        <label htmlFor="Versions">Choose a Version:</label>
                        <select name="Versions" id="Versions" onChange={(e) => changeVersion(e.target.value)}>
                                <option value="new">1.20 - 1.16</option>
                                <option value="mid">1.15 - 1.13</option>
                                <option value="old">1.12 - 1.8</option>
                        </select>

                        <label htmlFor="Flavours">Choose a Flavor:</label>
                        <select name="Flavours" id="Flavours" onChange={(e) => changeFlavour(e.target.value)}>
                            <optgroup label="JAVA">
                                <option value="Vanilla">Vanilla</option>
                                <option value="Plugins">Spigot, PaperMC, etc.</option>
                                <option value="Modded">Fabric, Forge, etc.</option>
                            </optgroup>
                            <optgroup label="Proxy">
                                <option value="Proxy">Bungeecord, Waterfall, etc.</option>
                                <option value="Proxy">Velocity</option>
                            </optgroup>
                            <optgroup label="Pocket-Edition">
                                <option value="Bedrock">Bedrock</option>
                                <option value="Bedrock">PocketmineMP</option>
                            </optgroup>
                        </select>

                        <label htmlFor="PlayerCount">Player count:</label>
                        <select name="PlayerCount" id="PlayerCount" onChange={(e) => changePlayer(e.target.value)}>
                                <option value="0">0 - 10</option>
                                <option value="1">10 - 30</option>
                                <option value="2">30 - 50</option>
                                <option value="3">50+</option>
                        </select>
                    </div>

                </div>
            </div>
                <br/>
                <br/>
                <br/>
                <h1 className="FeatureHeading">Features with each server!</h1>

            <div className="FeatureCards">
                <div className="Reason">
                    <div className="ReasonHeading">
                        <img src={version} alt="version"/>
                        <h3>Version Installer</h3>
                    </div>
                    <p>Seamless solution for effortlessly switching between different Minecraft versions on your server.</p>
                </div>
                <div className="Reason">
                    <div className="ReasonHeading">
                        <img src={mods} alt="mods"/>
                        <h3>Mods Installer</h3>
                    </div>
                    <p>Experience hassle-free installation and management of mods and modpacks on click of a button!</p>
                </div>
                <div className="Reason">
                    <div className="ReasonHeading">
                        <img src={settings} alt="settings"/>
                        <h3>SFTP Access</h3>
                    </div>
                    <p>Effortlessly manage your server files with easy and full SFTP access.</p>
                </div>
                <div className="Reason">
                    <div className="ReasonHeading">
                        <img src={discord} alt="discord"/>
                        <h3>Discord Support</h3>
                    </div>
                    <p>24/7 Discord support just a message away - get help anytime, anywhere.</p>
                </div>
                <div className="Reason">
                    <div className="ReasonHeading">
                        <img src={backups} alt="backups"/>
                        <h3>Daily backups</h3>
                    </div>
                    <p>Daily backups for peace of mind - protect your data and never lose progress.</p>
                </div>
                <div className="Reason">
                    <div className="ReasonHeading">
                        <img src={database} alt="database"/>
                        <h3>Free MySQL</h3>
                    </div>
                    <p>Free MySQL databases - power your server with reliable, fast database storage.</p>
                </div>
            </div>
        </div>
        </Layout>
    )
}









