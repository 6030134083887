import {getPlan, update} from "./Minecraft";
const presetValues = [1,2,3,4,5,6,7,8,10,12,14,16,20,24,32];
const ultimateValues = [8,12,16,20,24,28,32];

let auto = {
    new: {
        Vanilla: [8, 10, 14, 20],
        Plugins: [6, 8, 12, 16],
        Modded: [8, 12, 16, 20],
        Proxy: [1, 1, 1, 1],
        Bedrock: [2, 2, 4, 4]
    },
    mid: {
        Vanilla: [6, 8, 12, 16],
        Plugins: [6, 8, 10, 14],
        Modded: [8, 12, 16, 20],
        Proxy: [1, 1, 1, 1],
        Bedrock: [2, 2, 4, 4]
    },
    old: {
        Vanilla: [4, 6, 8, 12],
        Plugins: [4, 6, 6, 8],
        Modded: [8, 12, 16, 20],
        Proxy: [1, 1, 1, 1],
        Bedrock: [2, 2, 4, 4]
    }
}

let autoUltimate = {
    new: {
        Vanilla: [8, 12, 16, 20],
        Plugins: [8, 8, 12, 16],
        Modded: [8, 12, 16, 20]
    },
    mid: {
        Vanilla: [8, 8, 12, 16],
        Plugins: [8, 8, 12, 16],
        Modded: [8, 12, 16, 20]
    },
    old: {
        Vanilla: [8, 8, 8, 12],
        Plugins: [8, 8, 8, 8],
        Modded: [8, 12, 16, 20]
    }
}

let Version = "new";
let Flavour = "Vanilla";
let Player = 0;

export function aiSelector() {
    let RAM = document.getElementById("Ram");
    let newRAM;

    let plan = getPlan();

    if (plan === "Ultimate" && (Flavour === "Proxy" || Flavour === "Bedrock")) {
        document.getElementById("SupremeButton").click()
        newRAM = auto[Version][Flavour][Player];
        plan = getPlan();
    } else {
        newRAM = plan === "Ultimate" ? autoUltimate[Version][Flavour][Player] : auto[Version][Flavour][Player];
    }
    const Pos = plan === "Ultimate" ? ultimateValues.indexOf(newRAM) : presetValues.indexOf(newRAM);

    RAM.value = Pos;
    console.log(presetValues.indexOf(newRAM), newRAM);
    update();
}


export function changeVersion(value) {
    Version = value;
    aiSelector();
}

export function changeFlavour(value) {
    Flavour = value;
    aiSelector();
}

export function changePlayer(value) {
    Player = value;
    aiSelector();
}
